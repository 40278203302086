import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import Demo1 from "./Components/Demo1";
import StartupPage from "./Components/StartupPage";
// import {BrowserRouter,Route,Router} from 'react-router-dom'
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import Root, { rootLoader } from "./routes/root";
// import Team, { teamLoader } from "./routes/team";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  // const[meetingId,setMeetingId]=useState("ioks-rgru-c904")

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <Routes>
        {/* <Route exact path="/:meetingId" element={<StartupPage/>}> */}
        {/* <Route path="/" element={<StartupPage/>}>
          </Route> */}

        <Route path="/:company/:meetingId" element={<StartupPage />}></Route>
        <Route path="/videosdk/:meetingId" element={<Demo1 />}></Route>
      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
