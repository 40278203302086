import React, { useEffect,useState } from "react";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
  MeetingConsumer,
} from "@videosdk.live/react-sdk";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {useNavigate,useLocation} from "react-router-dom"
import { useParams } from 'react-router-dom';


function Demo1(props) {

  const[userName,setUserName]=useState("")
  var location=useLocation()
  const p=useParams();
  var {meetingId}=p;
  // location.state?location.state.name:""
 

  useEffect(() => {
    console.log("hjdsbchjdsbs",location.state)
    var script = document.createElement("script");
    script.type = "text/javascript";

    script.addEventListener("load", function (event) {
      const config = {
        name:location.state?location.state.name:"",
        // meetingId: "ioks-rgru-c904",
        meetingId: meetingId,
        // apiKey: "900db0a9-cfa0-4c06-aba1-cb7bf4d64fba",
        apiKey: "378c5971-9f73-494e-ba84-1d46661a5947",

        containerId: null,

        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,

        chatEnabled: true,
        screenShareEnabled: true,

        joinScreen: {
          visible: false,
          title: "Help Desk",
        },

        permissions: {
          removeParticipant: false,
          endMeeting: false,
        },
        pin: {
          allowed: false,
          layout: "SIDEBAR",
        },
        layout: {
          type: "SIDEBAR", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
          priority: "PIN", // "SPEAKER" | "PIN",
          // gridSize: 2,
        },
        /*

     Other Feature Properties
      
      */
      };

      const meeting = new VideoSDKMeeting();
      meeting.init(config);
    });
    console.log("find event",script)

    script.src =
      "https://sdk.videosdk.live/rtc-js-prebuilt/0.3.23/rtc-js-prebuilt.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  return (
    <>
      <h1>Demo</h1>
    </>
  );
}

export default Demo1;
