import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Demo1 from "./Demo1";
import "../Custom.css";
import { useParams } from "react-router-dom";
import MainLogo from "../Assets/header-main.svg";

function StartupPage(props) {
  const [name, setName] = useState("");
  // const[meetingId,setMeetingId]=useState("ioks-rgru-c904")
  const p = useParams();
  var { meetingId } = p;
  var navigate = useNavigate();
  //   useEffect(() => {
  //     // console.log("name===>", name);
  //   }, [name]);

  function handleClickFunction() {
    console.log("click");
    navigate(`/videosdk/${meetingId}`, { state: { name: name } });
  }

  return (
    <>
      <div className="container mainDiv">
        <div className="logo_container">
          <img src={MainLogo} alt="SignAble" />
        </div>
        <div class="mb-3" style={{ textAlign: "start" }}>
          <label
            for="exampleFormControlInput1"
            class="form-label"
            style={{ color: "white", marginBottom: "10px" }}
          >
            <h4>Name</h4>
          </label>
          <input
            type="text"
            style={{ width: "350px" }}
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Please enter your name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div>
          <button
            onClick={handleClickFunction}
            className="btn btn-primary"
            style={{ width: "180px", marginTop: "20px" }}
          >
            Join
          </button>
        </div>
      </div>
    </>
  );
}

export default StartupPage;
